.authSection {
  background-color: #d6e6f3;
  padding: 30px;
  height: 100vh;
  display: flex;
  align-items: center;
  position: relative;
}

.authSection-left {
  text-align: center;
}

.authSection-left img {
  margin-bottom: 50px;
  width: 60%;
}

.authSection-left button {
  border: none;
  border-radius: 1.5rem;
  padding: 6px 35px;
  /* background: #f8f9fa; */
  font-weight: bold;
  height: auto;
  line-height: normal;
  font-size: 14px;
}

.authSection-right {
  background: #f8f9fa;
  border-top-left-radius: 10% 50%;
  border-bottom-left-radius: 10% 50%;
  padding: 30px 50px 30px 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.authSection-right .authSection-heading {
  text-align: center;
  margin-bottom: 30px;
}

.authSection .lang_button{
  position: absolute;
  top: 30px;
  right: 30px;
  z-index: 99;
}

.react-tel-input .form-control{
  padding-right: 45px;
}

@media screen and (max-width: 1200px) {
  .authSection-right {
    padding: 30px 50px 30px 50px;
  }
}

@media screen and (max-width: 991px) {
  .authSection-left img {
    margin-bottom: 40px;
    width: 80%;
  }
}

@media screen and (max-width: 991px) {
  .authSection-left {
    margin: 50px 0;
  }

  .authSection-left img {
    margin-bottom: 40px;
    width: 80%;
    max-width: 250px;
  }
}

@media screen and (max-width: 767px) {
  .authSection {
    padding: 30px 0 0 0;
    display: block;
    height: auto;
  }

  .authSection-right {
    display: block;
    border-bottom-left-radius: 0;
    border-top-left-radius: 100px;
    border-top-right-radius: 100px;
  }
}

@media screen and (max-width: 575px) {
  .authSection-right {
    padding: 30px;
  }
}

	/* style for webview only */
  .authSection-left-webview {
    text-align: center;
    padding: 20px 0;
  }
  .authSection-left-webview img {
    margin-bottom: 0;
    width: 150px;
    height: auto;
  }
  .form-button-webview {
    border-radius: 1.2rem;
    line-height: 1;
  }