.ant-tooltip a {
  color: #fff;
}
.ant-card-body{
  padding: 20px !important;
}

body{
  margin: 0;
}

 /* for english */

 @font-face {
  font-family: 'Comfortaa';
  src: url('../../fonts/Comfortaa/Comfortaa-Bold.woff2') format('woff2'),
      url('../../fonts/Comfortaa/Comfortaa-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Comfortaa';
  src: url('../../fonts/Comfortaa/Comfortaa-Regular.woff2') format('woff2'),
      url('../../fonts/Comfortaa/Comfortaa-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/* for arabic */

@font-face {
  font-family: 'Noto Kufi Arabic';
  src: url('../../fonts/Noto_Kufi_Arabic/NotoKufiArabic-Bold.woff2') format('woff2'),
      url('../../fonts/Noto_Kufi_Arabic/NotoKufiArabic-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Noto Kufi Arabic';
  src: url('../../fonts/Noto_Kufi_Arabic/NotoKufiArabic-Regular.woff2') format('woff2'),
      url('../../fonts/Noto_Kufi_Arabic/NotoKufiArabic-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.app_ltr,
.app_ltr html,
.app_ltr div,
.app_ltr span,
.app_ltr p,
.app_ltr h1,
.app_ltr h2,
.app_ltr h3,
.app_ltr h4,
.app_ltr h5,
.app_ltr h6,
.app_ltr label,
.app_ltr input,
.app_ltr select,
.app_ltr textarea,
.app_ltr button,
.app_ltr optgroup{
  font-family: 'Comfortaa' !important;
}
.app_rtl,
.app_rtl html,
.app_rtl div,
.app_rtl span,
.app_rtl p,
.app_rtl h1,
.app_rtl h2,
.app_rtl h3,
.app_rtl h4,
.app_rtl h5,
.app_rtl h6,
.app_rtl label,
.app_rtl input,
.app_rtl select,
.app_rtl textarea,
.app_rtl button,
.app_rtl optgroup{
  font-family: 'Noto Kufi Arabic' !important;
}

/* style for sidebar menu */
.custom-menu .ant-menu-item, .custom-menu .ant-menu-submenu .ant-menu-submenu-title{
  display: grid !important;
  height: 85px;
  line-height: normal;
  align-items: center;
  justify-content: center;
  padding: 12px !important;
}
.custom-menu .ant-menu-item .ant-menu-item-icon, .custom-menu .ant-menu-submenu .ant-menu-item-icon{
  font-size: 20px !important;
  line-height: 0 !important;
}
.custom-menu .ant-menu-item .ant-menu-title-content, .custom-menu .ant-menu-submenu .ant-menu-title-content{
  margin: 0;
  opacity: 1 !important;
}
.custom-menu .ant-menu-item .ant-menu-title-content a {
  width: 100px;
  display: block;
  text-wrap: wrap;
  text-align: center;
  line-height: 18px;
}
.custom-menu li{
  margin: 0 !important;
  border-bottom: 1px solid #0000005c;
}
.ant-menu-item.custom-menu-popup{
  margin: 0 !important;
  border-bottom: 1px solid #0000005c;
}
.ant-page-header-back-button{
  padding: 10px !important;
  background: #f0f2f5 !important;
  border-radius: 35rem !important;
  border: 1px solid transparent !important;
}
.ant-page-header-back-button:hover{
  border-color:#8629ff !important;
  color: #8629ff;
}
.btn-success {
  color: #389e0d;
  background: #f6ffed;
  border-color: #b7eb8f;
}
