.add_order_modal .ant-modal-header,
.add_order_modal .ant-modal-close {
  display: none;
}
.add_order_modal .ant-modal-footer {
  text-align: center;
}
.product_cart img{
    width: 150px;
    height: auto;
    margin-bottom: 20px;
    object-fit: contain;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 10px 0px;
    border-radius: 35rem;
    padding: 10px;
}
.add_order_modal img{
    width: 95px;
    height: auto;
    margin-bottom: 10px;
    object-fit: contain;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 10px 0px;
    border-radius: 35rem;
    padding: 10px;
}
@media screen and (max-width: 575px) {
    .product_cart img{
        width: 95px;
    }
}

.full_width_tab .ant-tabs-nav{
  width: 100% !important;
}
.full_width_tab .ant-tabs-tab {
  display: block; /* centers text inside tabs */
  flex: 1;
  text-align: center;
}

.full_width_tab .ant-tabs-nav > div:nth-of-type(1) {
  display: unset !important;
  width: 100% !important;
}

.tab_bottom > .ant-tabs-nav, .tab_bottom > div > .ant-tabs-nav {
  order: 1;
  margin-top: 16px;
  margin-bottom: 0;
  position: fixed;
  bottom: 0;
  background: green;
z-index: 999;
}