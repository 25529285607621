#transaction-items-table {
  width: 100%;
  text-align: "center";
}

#transaction-items-table th {
  background: #f8f8f8;
}

#transaction-items-table th,
#transaction-items-table td {
  border-bottom: 1px solid #f0f0f0;
  padding: 12px 0;
  text-align: center;
}

#transaction-items-table .right-border {
  border-right: 1px solid #f0f0f0;
}
#transaction-items-table .left-border {
  border-left: 1px solid #f0f0f0;
}

#transaction-items-table tr:last-of-type td {
  border-bottom: none;
}
