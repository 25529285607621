.pos_tabs .ant-tabs-nav{
  padding: 20px;
  margin: 0 0 20px 0;
  background-color: #ffffff;
}
.pos_tabs .ant-tabs-tab{
  border: 2px solid transparent;
  padding: 2px 15px;
  margin-right: 10px !important;
  border-radius: 10px;
}
.pos_tabs .ant-tabs-tab + .ant-tabs-tab {
  margin: 0;
}
.pos_tabs .ant-tabs-ink-bar{
  display: none;
}
.pos_tabs .ant-tabs-tab.ant-tabs-tab-active{
  border-color: #6301f2;
  background-color: #f4e6ff;
}



/* cart style */
.brand-color-purple {
  color: #6301f2;
}
.quantity_row{
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap:  10px;
}
.cart_card .ant-card-body{
  max-height: calc(100vh - 320px);
  overflow-y: auto;
}
@media screen and (max-width: 1400px) {
  .quantity_row {
    flex-direction: column;
  }
  .cart_card .ant-card-body{
    max-height: 55vh;
  }
}
@media screen and (max-width: 1300px) {
  .cart_card .ant-card-body{
    max-height: 50vh;
  }
}